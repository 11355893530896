import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import './sidebar.scss'

export default class SideBar extends Component {

    render() {
        const { activeMenuItem } = this.props
        return (
            <div className="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg">
                <div className="logo">
                    <span className="simple-text logo-normal">
                        SMETA
                    </span>
                </div>
                <div className="sidebar-wrapper">
                    <ul className="nav">
                        <li className={classnames({
                            'nav-item': true,
                            'active': activeMenuItem === 'Новый заказ',
                        })}>
                            <Link to={'/add-order'} className="nav-link">
                                <i className="material-icons">widgets</i>
                                <p>Новый заказ</p>
                            </Link>
                        </li>
                        <li className={classnames({
                            'nav-item': true,
                            'active': activeMenuItem === 'Статистика',
                        })}>
                            <Link to={'/statistic'} className="nav-link">
                                <i className="material-icons">maps</i>
                                <p>Статистика</p>
                            </Link>
                        </li>
                        <li className={classnames({
                            'nav-item': true,
                            'active': activeMenuItem === 'Библиотека',
                        })}>
                            <Link to={'/library'} className="nav-link">
                                <i className="material-icons">content_paste</i>
                                <p>Библиотека</p>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}